<template>
  <div class="loading">
    <div class="loading__container"> 
      <div class="loading__logo">
        <svg id="uuid-fc4cac68-3922-43f5-b5d6-c53de49c8261" data-name="uuid-4e704f2a-c3d4-475d-8d00-2782c06004b4" xmlns="http://www.w3.org/2000/svg" width="200" height="34" viewBox="0 0 200 34">
          <path class="path pathO pathO--1" d="M66.07,13.1c-1.03-1-2.25-1.79-3.66-2.36s-2.95-.86-4.6-.86-3.23,.29-4.64,.88c-1.42,.59-2.64,1.39-3.68,2.4s-1.85,2.2-2.44,3.54c-.59,1.35-.88,2.8-.88,4.34v.08c0,1.55,.29,3,.88,4.34,.59,1.35,1.39,2.52,2.42,3.52s2.25,1.79,3.66,2.36c1.41,.57,2.95,.86,4.6,.86s3.23-.29,4.64-.88c1.41-.59,2.64-1.39,3.68-2.4s1.85-2.19,2.44-3.54c.59-1.35,.88-2.8,.88-4.34v-.08c0-1.55-.29-3-.88-4.34-.59-1.35-1.39-2.52-2.42-3.52Zm-2.7,8.03c0,.8-.13,1.56-.38,2.26s-.62,1.33-1.1,1.86-1.06,.95-1.74,1.26-1.46,.46-2.34,.46-1.62-.16-2.3-.48-1.27-.75-1.78-1.3-.89-1.17-1.16-1.88-.4-1.46-.4-2.26v-.08c0-.8,.13-1.55,.38-2.26,.25-.71,.62-1.33,1.1-1.86s1.06-.95,1.74-1.26,1.46-.46,2.34-.46c.83,0,1.59,.16,2.28,.48s1.29,.75,1.8,1.3,.89,1.17,1.16,1.88,.4,1.46,.4,2.26v.08Z" style="fill: #394a7a;"/>
          <path class="path pathO pathO--2" d="M127.06,13.1c-1.03-1-2.25-1.79-3.66-2.36s-2.95-.86-4.6-.86-3.23,.29-4.64,.88c-1.42,.59-2.64,1.39-3.68,2.4s-1.85,2.2-2.44,3.54c-.59,1.35-.88,2.8-.88,4.34v.08c0,1.55,.29,3,.88,4.34,.59,1.35,1.39,2.52,2.42,3.52s2.25,1.79,3.66,2.36c1.41,.57,2.95,.86,4.6,.86s3.23-.29,4.64-.88c1.41-.59,2.64-1.39,3.68-2.4,1.04-1.01,1.86-2.19,2.44-3.54,.59-1.35,.88-2.8,.88-4.34v-.08c0-1.55-.29-3-.88-4.34-.59-1.35-1.39-2.52-2.42-3.52Zm-2.7,8.03c0,.8-.13,1.56-.38,2.26s-.62,1.33-1.1,1.86c-.48,.53-1.06,.95-1.74,1.26s-1.46,.46-2.34,.46-1.62-.16-2.3-.48-1.27-.75-1.78-1.3-.89-1.17-1.16-1.88c-.27-.71-.4-1.46-.4-2.26v-.08c0-.8,.13-1.55,.38-2.26,.25-.71,.62-1.33,1.1-1.86,.48-.53,1.06-.95,1.74-1.26s1.46-.46,2.34-.46c.83,0,1.59,.16,2.28,.48s1.29,.75,1.8,1.3,.89,1.17,1.16,1.88c.27,.71,.4,1.46,.4,2.26v.08Z" style="fill: #394a7a;"/>
          <path class="path pathE pathE--1" d="M182.43,17.14c-.41-1.4-1.05-2.64-1.92-3.72s-1.96-1.94-3.26-2.58c-1.31-.64-2.86-.96-4.64-.96-1.58,0-3.01,.29-4.3,.88-1.29,.59-2.41,1.39-3.34,2.4s-1.66,2.2-2.18,3.56c-.52,1.36-.78,2.8-.78,4.32v.08c0,1.66,.28,3.16,.84,4.5,.56,1.35,1.34,2.52,2.34,3.5,1,.99,2.19,1.75,3.56,2.28s2.86,.8,4.46,.8c1.95,0,3.64-.35,5.08-1.06s2.67-1.69,3.68-2.94l-3.48-3.08c-.85,.77-1.68,1.33-2.48,1.68s-1.71,.52-2.72,.52c-1.36,0-2.5-.35-3.42-1.06s-1.53-1.75-1.82-3.14h14.93c.03-.29,.05-.57,.06-.84s.02-.52,.02-.76c0-1.52-.21-2.98-.62-4.38h-.01Zm-14.47,2.18c.24-1.39,.75-2.5,1.54-3.32,.79-.83,1.82-1.24,3.1-1.24s2.31,.42,3.08,1.26,1.25,1.94,1.44,3.3h-9.16Z" style="fill: #394a7a;"/>
          <g>
            <path class="path pathC pathC--1" d="M31.96,32c4.14,0,7.89-1.68,10.61-4.39l-10.61-10.61v15Z" style="fill: #cca872;"/>
            <path class="path pathC pathC--2" d="M21.35,27.61c2.71,2.71,7.07,4.8,11.99,4.33l-1.38-14.94s-10.61,10.61-10.61,10.61Z" style="fill: #cca872;"/>
            <path class="path pathC pathC--3" d="M16.96,17c0,4.14,1.97,8.82,5.43,11.55l9.57-11.55h-15Z" style="fill: #cca872;"/>
            <path class="path pathC pathC--4" d="M21.35,6.39c-2.71,2.72-4.65,6.54-4.33,11.97l14.94-1.36L21.35,6.39Z" style="fill: #cca872;"/>
            <path class="path pathC pathC--5" d="M31.96,2c-4.14,0-8.42,1.72-11.47,5.33l11.47,9.67V2Z" style="fill: #cca872;"/>
            <path class="path pathC pathC--6" d="M42.57,6.39c-2.72-2.71-6.91-4.7-11.82-4.34l1.21,14.95s10.61-10.61,10.61-10.61Z" style="fill: #cca872;"/>
          </g>
          <g>
            <path class="path pathM pathM--1" d="M102.96,17c0-4.14-1.68-7.89-4.39-10.61l-10.61,10.61h15Z" style="fill: #ff7a69;"/>
            <path class="path pathM pathM--2" d="M98.57,27.61c2.71-2.71,4.9-6.93,4.31-12.15l-14.92,1.54,10.61,10.61Z" style="fill: #ff7a69;"/>
            <path class="path pathM pathM--3" d="M87.96,32c4.14,0,8.74-1.9,11.58-5.46l-11.58-9.54v15Z" style="fill: #ff7a69;"/>
            <path class="path pathM pathM--4" d="M77.35,27.61c2.71,2.71,6.84,4.77,12.18,4.31l-1.57-14.92-10.61,10.61Z" style="fill: #ff7a69;"/>
            <path class="path pathM pathM--5" d="M72.96,17c0,4.14,2.02,8.82,5.51,11.62l9.49-11.62h-15Z" style="fill: #ff7a69;"/>
            <path class="path pathM pathM--6" d="M77.35,6.39c-2.71,2.71-4.78,6.91-4.32,12.08l14.93-1.47s-10.61-10.61-10.61-10.61Z" style="fill: #ff7a69;"/>
          </g>
          <g>
            <path class="path pathD pathD--1" d="M153.85,8.1c-2.53-2.53-6.03-4.1-9.9-4.1v14l9.9-9.9Z" style="fill: #bfb6a8;"/>
            <path class="path pathD pathD--2" d="M157.96,18c0-3.87-1.62-8.07-5.45-11.08l-8.55,11.08h14Z" style="fill: #bfb6a8;"/>
            <path class="path pathD pathD--3" d="M143.96,18l9.9,9.9c2.53-2.53,4.42-6.28,4.03-11.29l-13.93,1.39Z" style="fill: #bfb6a8;"/>
            <path class="path pathD pathD--4" d="M143.96,32c3.87,0,7.96-1.67,10.77-5.06l-10.77-8.94v14Z" style="fill: #bfb6a8;"/>
            <rect class="path pathD pathD--5" x="136" y="18" width="8.5" height="14" style="fill: #bfb6a8;"/>
            <rect class="path pathD pathD--6" x="136" y="4" width="8.5" height="15" style="fill: #bfb6a8;"/>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style src="./../css/reset.css">

</style>
<style src="./../css/loading.css">

</style>