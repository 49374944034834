// External imports
import Vue from 'vue';
import moment from 'moment';

import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);

import { DraggablePlugin } from '@braks/revue-draggable';
Vue.use(DraggablePlugin);

// Listening to multiple events
import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce, {
	listenTo: ['input', 'keyup'],
	lock: true,
});

// Internal imports
import App from './App.vue';
import router from './routes/routes';
import i18n from './plugins/i18n';
import socketClient from './services/SOCKET';
import ClickOuside from './directives/clickOuside.directive';
import { store } from './store/store';

import './assets/styles/init.scss';
import './assets/styles/foundation.scss';
import './assets/styles/utility.scss';
import './assets/styles/fonts.scss';
import './assets/styles/components/init.scss';

if (process.env.NODE_ENV === 'production') {
	console.log = function () { };
}

// Setup socket connection
socketClient.setup();
socketClient.listen('disconnect', function (reason) {
	console.log({ socketEvent: 'Disconnected! ', reason });
});

Vue.config.productionTip = false;

Vue.directive('click-outside', ClickOuside);

/**
 * Global filter to format number to string
 * @param {Number}
 * @returns string 000,000
 */
Vue.filter('formatToStringNumber', function (value) {
	if (value && typeof value === 'number') {
		return new Intl.NumberFormat('ja-JP').format(value);
	}

	return value;
});

/**
 * Global filter to format datetime string to date string
 * @param {Datetime String}
 * @returns datestring format YYYY-MM-DD
 */
Vue.filter('formatToDateString', function (value) {
	if (value && typeof value === 'string') {
		return moment(value).format('YYYY/MM/DD');
	}
	return value;
});

Vue.filter('formatToJapanDateString', function (value) {
	if (value) {
		let momentDate = moment(value);
		let year = momentDate.format('YYYY');
		let month = momentDate.format('MM');
		let day = momentDate.format('DD');

		return `${year} 年 ${month} 月 ${day} 日`;
	}
	return value;
});

Vue.filter('splitAddress', function (value) {
	if (!value) return value;

	let result = value.replace(' ', '\n');
	return result;
});

Vue.filter('doubleZeros', function (value) {
	if (value !== 0) return value;
	let doubleZero = '00';
	return doubleZero;
});

new Vue({
	router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount('#app');
