<template>
	<body id="app">
		<ProcessingSpinner
			class="appProcessing"
			:class="{ 'appProcessing--reloading': isAppReloading }"
			v-show="isAppProcessing || isAppReloading"
		/>
		<LoadingSpinner class="appLoading" v-show="isAppLoading" />

		<router-view />
	</body>
</template>

<script>
import ProcessingSpinner from '@/components/Spinner/Processing/Processing.vue';
import LoadingSpinner from '@/components/Spinner/Loading/Loading.vue';

import KeyService from '@/services/API/key.service.js'

import { mapState } from 'vuex';

export default {
	name: 'App',
	components: {
		ProcessingSpinner,
		LoadingSpinner,
	},
	data: function () {
		return {
			width: window.innerWidth,
			height: window.innerHeight,
			isAppReloading: false,
		};
	},
	computed: {
		...mapState(['isAppProcessing', 'isAppLoading']),
	},
	methods: {
		update: function () {
			this.width = window.innerWidth;
			this.height = window.innerHeight;
			document.documentElement.style.setProperty(
				'--dvh',
				window.innerHeight * 0.01 + 'px'
			);
		},

		isInitProject() {
			const path = window.location.href;

			const lastItem = path.split('/').pop();

			return lastItem === '';
		},
	},
	mounted: async function () {
		this.update();
		window.addEventListener('resize', this.update);

		if (this.isInitProject()) {
			this.$store.commit('setIsAppLoading', true);
			setTimeout(() => {
				this.$store.commit('setIsAppLoading', false);
			}, 3400);
		} else {
			this.isAppReloading = true;
			setTimeout(() => {
				this.isAppReloading = false;
			}, 3300);
		}
		let stable_diff_url = await KeyService.get('public_url')
		console.log(stable_diff_url.data.value)
		sessionStorage.setItem('stable_diff_url', stable_diff_url.data.value)
	},
	beforeDestroy: function () {
		window.removeEventListener('resize', this.update);
	},
};
</script>
<style src="./App.css" scoped></style>
