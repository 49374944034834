import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '../services/API/auth.service';
import { store } from '../store/store';
Vue.use(Router);

// Catch navigation error
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject)
		return originalPush.call(this, location, onResolve, onReject);
	return originalPush.call(this, location).catch((err) => {
		console.log(err);
	});
};

// const MODE = process.env.VUE_APP_MODE;

// const CREATOR_PATH =
// 	MODE != 'PRODUCTION' ? '/creatorApp/:projectId/:token/' : '/creatorApp/:projectId/';
const CREATOR_PATH = '/creatorApp/:projectId/:token/';

// const CLIENT_PATH =
// 	MODE != 'PRODUCTION' ? '/clientApp/:projectId/:token/' : '/clientApp/:projectId/';
const CLIENT_PATH = '/clientApp/:projectId/:token/';
// const PREVIEW_PATH =
// 	MODE != 'PRODUCTION'
// 		? '/clientApp/readonly/:projectId/:token/'
// 		: '/clientApp/readonly/:projectId/';

const PREVIEW_PATH = '/clientApp/readonly/:projectId/:token/';

const CLIENT_MODE_PATH = '/creatorApp/clientmode/:projectId/:token/';
//--------------------------------
const clientRoutes = {
	name: 'ClientApp',
	path: CLIENT_PATH,
	component: () => import('@/views/ClientApp/ClientApp.vue'),
	children: [
		{
			path: '',
			component: () =>
				import('@/views/ClientApp/ProcessingScreen/ProcessingScreen.vue'),
		},
		{
			path: 'finish',
			component: () =>
				import('@/views/ClientApp/ProcessingScreen/ProcessingScreen.vue'),
		},
		/**
		 * Side bar left routes
		 */
		{
			path: 'delivery',
			component: () => import('@/views/ClientApp/DeliveryScreen/DeliveryScreen.vue'),
		},
		{
			path: 'creative',
			component: () => import('@/views/ClientApp/CreativeScreen/CreativeScreen.vue'),
		},
		{
			path: 'estimate',
			component: () => import('@/views/ClientApp/EstimateScreen/EstimateScreen.vue'),
		},
		{
			path: 'decision',
			component: () => import('@/views/ClientApp/DecisionScreen/DecisionScreen.vue'),
		},
		{
			path: 'hearing',
			component: () => import('@/views/ClientApp/Hearing/HearingScreen.vue'),
		},
		{
			path: 'schedule',
			component: () => import('@/views/ClientApp/ScheduleScreen/ScheduleScreen.vue'),
		},
		{
			path: 'proposal',
			component: () => import('@/views/ClientApp/ProposalScreen/ProposalScreen.vue'),
		},
		{
			path: 'settlement',
			component: () =>
				import('@/views/ClientApp/SettlementScreen/SettlementScreen.vue'),
		},
		{
			path: 'addcost',
			component: () => import('@/views/ClientApp/AddCostScreen/AddCostScreen.vue'),
		},
		{
			path: 'reproposal',
			component: () => import('@/views/ClientApp/ReProposal/ReProposalScreen.vue'),
		},
		{
			path: 'download',
			component: () =>
				import('@/views/ClientApp/DocumentDownLoadScreen/DocumentDownLoadScreen.vue'),
		},
	],
	beforeEnter: (to, from, next) => {
		getInfoEachRouter(to, from, next);
	},
};
const creatorRoutes = {
	name: 'CreatorApp',
	path: CREATOR_PATH,
	component: () => import('@/views/CreatorApp/CreatorApp.vue'),
	children: [
		{
			path: '',
			component: () =>
				import('@/views/CreatorApp/ProcessingScreen/ProcessingScreen.vue'),
		},
		{
			path: 'finish',
			component: () =>
				import('@/views/CreatorApp/ProcessingScreen/ProcessingScreen.vue'),
		},
		/**
		 * Side bar left routes
		 */
		{
			path: 'delivery',
			component: () => import('@/views/CreatorApp/DeliveryScreen/DeliveryScreen.vue'),
		},
		{
			path: 'creative',
			component: () => import('@/views/CreatorApp/CreativeScreen/CreativeScreen.vue'),
		},
		{
			path: 'estimate',
			component: () => import('@/views/CreatorApp/EstimateScreen/EstimateScreen.vue'),
		},
		{
			path: 'decision',
			component: () => import('@/views/CreatorApp/DecisionScreen/DecisionScreen.vue'),
		},
		{
			path: 'hearing',
			component: () => import('@/views/CreatorApp/Hearing/HearingScreen.vue'),
		},
		{
			path: 'schedule',
			component: () => import('@/views/CreatorApp/ScheduleScreen/ScheduleScreen.vue'),
		},
		{
			path: 'proposal',
			component: () => import('@/views/CreatorApp/ProposalScreen/ProposalScreen.vue'),
		},
		{
			path: 'settlement',
			component: () =>
				import('@/views/CreatorApp/SettlementScreen/SettlementScreen.vue'),
		},
		{
			path: 'addcost',
			component: () => import('@/views/CreatorApp/AddCostScreen/AddCostScreen.vue'),
		},
		{
			path: 'reproposal',
			component: () => import('@/views/CreatorApp/ReProposal/ReProposalScreen.vue'),
		},
		{
			path: 'download',
			component: () =>
				import('@/views/CreatorApp/DocumentDownLoadScreen/DocumentDownLoadScreen.vue'),
		},
		{
			path: 'nft',
			component: () => import('@/views/CreatorApp/NftUpload/NftUpload.vue'),
		},
	],
	beforeEnter: (to, from, next) => {
		getInfoEachRouter(to, from, next);
	},
};
const previewRoutes = {
	...clientRoutes,
	path: PREVIEW_PATH,
};

const clientModeRoutes = {
	...clientRoutes,
	path: CLIENT_MODE_PATH,
};
//--------------------------------
var routers = new Router({
	mode: 'history',
	routes: [
		{
			name: 'ResetScreen',
			path: '/resetdatabase',
			component: () => import('@/views/ResetScreen/ResetScreen.vue'),
		},
		{
			name: 'ComodeAI',
			path: '/comode-ai',
			component: () => import('@/views/General/ComodeAIScreen/ComodeAI.vue'),
		},
		/****************************************
		 * Creator App routes
		 ****************************************/
		creatorRoutes,
		/****************************************
		 * Client App routes
		 ****************************************/
		clientRoutes,
		/****************************************
		 * Preview App routes
		 ****************************************/
		previewRoutes,
		/****************************************
		 * Client Mode App routes
		 * **************************************/
		clientModeRoutes,
	],
});

const getInfoEachRouter = async (to, from, next) => {
	console.log({ from, to, next });
	var token = to.params.token;
	try {
		await AuthService.verifyProjectToken(token);
	} catch (err) {
		next({
			path: '/notfound',
		});
	}
	if (to.fullPath.split('/').includes('readonly')) {
		store.commit('setPreview', 1);
	}
	if (to.fullPath.split('/').includes('clientmode')) {
		store.commit('setClientMode', true);
	}
	next();
};

export default routers;
