import ApiService from './api.service';

const serviceURL = 'key';
const KeyService = {
	/**
	 * Get value by key
	 * @param {string} key
	 * @returns value
	 * @throws Error
	 */
	get(key) {
		return ApiService.get(`${serviceURL}`,key);
	}
};

export default KeyService;
