import Vue from 'vue';
import VueI18n from 'vue-i18n';

import jpGeneral from '@/locales/jp/general.json';
import jpCreatorApp from '@/locales/jp/creator-app.json';
import jpClientApp from '@/locales/jp/client-app.json';
import jpRequirementDetail from '@/locales/jp/requirement-detail-screen.json';
import jpEstimateScreen from '@/locales/jp/estimate-screen.json';
import jpScheduleScreen from '@/locales/jp/schedule-screen.json';
import jpHearingScreen from '@/locales/jp/hearing-screen.json';
import jpCreativeScreen from '@/locales/jp/creative-screen.json';
import jpSuggestionScreen from '@/locales/jp/proposal-screen.json';
import jpFixScreen from '@/locales/jp/fix-screen.json';
import jpDeliveryScreen from '@/locales/jp/delivery-screen.json';
import jpLiveScreen from '@/locales/jp/live-screen.json';
import jpChatScreen from '@/locales/jp/chat-screen.json';
import jpSideNavBar from '@/locales/jp/side-nav-bar.json';

Vue.use(VueI18n);

const messages = {
	jp: {
		general: jpGeneral['general'],
		creatorApp: jpCreatorApp['creatorApp'],
		clientApp: jpClientApp['clientApp'],
		requirementDetail: jpRequirementDetail['requirementDetail'],
		estimateScreen: jpEstimateScreen['estimateScreen'],
		scheduleScreen: jpScheduleScreen['scheduleScreen'],
		hearingScreen: jpHearingScreen['hearingScreen'],
		creativeScreen: jpCreativeScreen['creativeScreen'],
		proposalScreen: jpSuggestionScreen['proposalScreen'],
		fixScreen: jpFixScreen['fixScreen'],
		deliveryScreen: jpDeliveryScreen['deliveryScreen'],
		liveScreen: jpLiveScreen['liveScreen'],
		chatScreen: jpChatScreen['chatScreen'],
		sideNavBar: jpSideNavBar['sideNavBar'],
	},
	// 'en': {},
};

const i18n = new VueI18n({
	locale: 'jp',
	fallbackLocale: 'jp',
	messages,
});

export default i18n;
