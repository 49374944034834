import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {
		projectId: undefined,
		userId: undefined,
		token: undefined,
		projectInfo: {},
		managementMasterInfo: {},
		creatorInfo: {},
		creatorAvatar: undefined,
		clientInfo: {},
		clientAvatar: undefined,
		schedule: {},
		pieChart: undefined,
		preview: false,
		clientMode: false,
		appLogo: '',
		appLogoPlain: '',
		redDotIndexs: [],
		/**
		 * Show spinner when doing process
		 */
		isAppProcessing: false,
		/**
		 * Show spinner when first time loading app
		 */
		isAppLoading: false,

		/**
		 * Enable or disable sound of App
		 */
		isSoundEnabled: true,

		/**
		 * Handle displaying scroll badge
		 */
		isControlScrollDecision: false,
		isNftUploading: false,
		isShareCoCreate: false,
		isCoCreateOpen: false,
	},
	mutations: {
		setProjectId: (state, projectId) => (state.projectId = projectId),
		setToken: (state, token) => (state.token = token),
		setUserId: (state, userId) => (state.userId = userId),
		setCreatorInfo: (state, creatorInfo) => (state.creatorInfo = creatorInfo),
		setCreatorAvatar: (state, creatorAvatar) => (state.creatorAvatar = creatorAvatar),
		setClientInfo: (state, clientInfo) => (state.clientInfo = clientInfo),
		setClientAvatar: (state, clientAvatar) => (state.clientAvatar = clientAvatar),
		setProjectInfo: (state, projectInfo) => (state.projectInfo = projectInfo),
		setManagementMasterInfo: (state, managementMasterInfo) =>
			(state.managementMasterInfo = managementMasterInfo),
		setSchedule: (state, schedule) => {
			state.schedule = schedule;
		},
		setPieChart: (state, pieChart) => (state.pieChart = pieChart),
		setPreview: (state, preview) => (state.preview = preview),
		setClientMode: (state, clientMode) => (state.clientMode = clientMode),
		setAppLogo: (state, appLogo) => (state.appLogo = appLogo),
		setAppLogoPlain: (state, appLogoPlain) => (state.appLogoPlain = appLogoPlain),
		setRedDotIndexs: (state, redDotIndexs) => (state.redDotIndexs = redDotIndexs),
		setIsAppProcessing: (state, isAppProcessing) =>
			(state.isAppProcessing = isAppProcessing),
		setIsAppLoading: (state, isAppLoading) => (state.isAppLoading = isAppLoading),
		setIsSoundEnabled: (state, isSoundEnabled) =>
			(state.isSoundEnabled = isSoundEnabled),
		setIsControlScrollDecision: (state, isControlScrollDecision) =>
			(state.isControlScrollDecision = isControlScrollDecision),
		setIsNftUploading: (state, isNftUploading) =>
			(state.isNftUploading = isNftUploading),
		setIsShareCoCreate: (state, isShareCoCreate) => {
			state.isShareCoCreate = isShareCoCreate;
		},
		setIsCoCreateOpen: (state, isCoCreateOpen) => {
			state.isCoCreateOpen = isCoCreateOpen;
		},
	},
});
