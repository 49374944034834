import {axios} from "../API/api.service";



const serviceURL = 'token';
const AuthService = {
	/**
	 * Verify Project Token
	 * @param {Token} token
	 * @returns {Cookies} Cookies
	 */
	verifyProjectToken(token) {
		return axios.get(`${serviceURL}/verify/project/${token}`);
	},
	/**
	 * Verify Project and user is not deleted
	 * @param {string} projectId
	 * @returns {Boolean} Boolean
	 */
	verifyProjectIsNotDeleted(projectId) {
		return axios.get(`${serviceURL}/verify/projectId/${projectId}`);
	}
};

export default AuthService;
