<template>
	<div class="process">
		<div class="process__container">
			<div class="process__logo">
				<svg
					id="uuid-b7f9a09b-b238-4896-a4b6-9480247eeae8"
					data-name="uuid-4e704f2a-c3d4-475d-8d00-2782c06004b4"
					xmlns="http://www.w3.org/2000/svg"
					width="34"
					height="34"
					viewBox="0 0 34 34"
				>
					<path
						class="path pathC pathC--1"
						d="M18.39,32c4.14,0,7.89-1.68,10.61-4.39l-10.61-10.61v15Z"
						style="fill: #cca872"
					/>
					<path
						class="path pathC pathC--2"
						d="M7.78,27.61c2.71,2.71,7.07,4.8,11.99,4.33l-1.38-14.94S7.78,27.61,7.78,27.61Z"
						style="fill: #cca872"
					/>
					<path
						class="path pathC pathC--3"
						d="M3.39,17c0,4.14,1.97,8.82,5.43,11.55l9.57-11.55H3.39Z"
						style="fill: #cca872"
					/>
					<path
						class="path pathC pathC--4"
						d="M7.78,6.39c-2.71,2.72-4.65,6.54-4.33,11.97l14.94-1.36L7.78,6.39Z"
						style="fill: #cca872"
					/>
					<path
						class="path pathC pathC--5"
						d="M18.39,2c-4.14,0-8.42,1.72-11.47,5.33l11.47,9.67V2Z"
						style="fill: #cca872"
					/>
					<path
						class="path pathC pathC--6"
						d="M29,6.39c-2.72-2.71-6.91-4.7-11.82-4.34l1.21,14.95S29,6.39,29,6.39Z"
						style="fill: #cca872"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style src="./../css/reset.css"></style>
<style src="./../css/process.css"></style>
